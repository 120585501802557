// Cookies Consent
// @import "../../node_modules/vanilla-cookieconsent/src/cookieconsent.css";

@import 'utils/breakpoints';

// UTILS
@import 'base/helpers';
@import 'utils/functions';

// ABSTRACTS (Or Utilities)
@import 'abstracts/mixins';

// BASE
@import 'base/helpers';
@import 'base/colors';
@import 'base/typography';


$easeInOutQuint: cubic-bezier(0.83, 0, 0.17, 1);

/** Light color-scheme **/
/** Dark color-scheme **/
/* Make elements "animatable" */
:root {
	--cc-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
	--cc-font-size: 1.4rem;
	--cc-bg: $white;
	--cc-text: $black;
	--cc-border-radius: 0;
	--cc-btn-primary-bg: $primary;
	--cc-btn-primary-text: $white;
	--cc-btn-primary-hover-bg: $primary;
	--cc-btn-primary-hover-text: $white;
	--cc-btn-secondary-bg: $gray-300;
	--cc-btn-secondary-text: $black;
	--cc-btn-secondary-hover-bg: $gray-400;
	--cc-btn-secondary-hover-text: $black;
	--cc-btn-border-radius: 0;
	--cc-toggle-bg-off: $gray-500;
	--cc-toggle-bg-on: $primary;
	--cc-toggle-bg-readonly: #d5dee2;
	--cc-toggle-knob-bg: #fff;
	--cc-toggle-knob-icon-color: $gray-200;
	--cc-block-text: $black;
	--cc-cookie-category-block-bg: #f0f4f7;
	--cc-cookie-category-block-bg-hover: $gray-100;
	--cc-section-border: #f1f3f5;
	--cc-cookie-table-border: $gray-300;
	--cc-overlay-bg: $gray-900;
	--cc-overlay-opacity: .85;
	--cc-consent-modal-box-shadow: 0 0.625rem 1.875rem rgba(2, 2, 3, 0.28);
	--cc-webkit-scrollbar-bg: #cfd5db;
	--cc-webkit-scrollbar-bg-hover: #9199a0;
}


.c_darkmode {
	--cc-bg: #181b1d;
	--cc-text: #d8e5ea;
	--cc-btn-primary-bg: #a6c4dd;
	--cc-btn-primary-text: #000;
	--cc-btn-primary-hover-bg: #c2dff7;
	--cc-btn-primary-hover-text: $white;
	--cc-btn-secondary-bg: #33383c;
	--cc-btn-secondary-text: $black;
	--cc-btn-secondary-hover-bg: #3e454a;
	--cc-btn-secondary-hover-text: $black;
	--cc-toggle-bg-off: #667481;
	--cc-toggle-bg-on: $primary;
	--cc-toggle-bg-readonly: #454c54;
	--cc-toggle-knob-bg: var(--cc-cookie-category-block-bg);
	--cc-toggle-knob-icon-color: $white;
	--cc-block-text: #b3bfc5;
	--cc-cookie-category-block-bg: #23272a;
	--cc-cookie-category-block-bg-hover: #2b3035;
	--cc-section-border: #292d31;
	--cc-cookie-table-border: #2b3035;
	--cc-webkit-scrollbar-bg: #667481;
	--cc-webkit-scrollbar-bg-hover: #9199a0;
}

// Consent::Container
#cc_div #cm {
    display: block !important;
}

.cc_div {
    color: $black;
    font: {
	    family: var(--cc-font-family);
        size: var(--cc-font-size);
	    weight: 400;
    }
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;

	* {
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		float: none;
        padding: 0;
        margin: 0;
        height: auto;
        color: inherit;
		font: {
            style: inherit;
		    variant: normal;
		    weight: inherit;
		    family: inherit;
		    size: 1em;
        }
        letter-spacing: unset;
		line-height: 1.2;
		text: {
            align: left;
            decoration: none;
            // transform: none;
        }
		vertical-align: baseline;
		transition: none;
		animation: none;
		background: none;
		border: none;
		border-radius: unset;
		box-shadow: none;
		visibility: unset;
	}

	&:before,
    &:after {
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		float: none;
        height: auto;
		font: {
            style: inherit;
		    variant: normal;
		    weight: inherit;
		    family: inherit;
		    size: 1em;
        }
        letter-spacing: unset;
		line-height: 1.2;
        text: {
            transform: none;
            decoration: none;
            align: left;
        }
		vertical-align: baseline;
		transition: none;
		animation: none;
		margin: 0;
		padding: 0;
		color: inherit;
		background: none;
		border: none;
		border-radius: unset;
		box-shadow: none;
		visibility: unset;
	}

	#cm,
    .c-bl,
    .b-tl {
		border-radius: var(--cc-border-radius);
	}

	input {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		overflow: hidden;
	}

	button {
		// -webkit-appearance: none;
		// -moz-appearance: none;
		// appearance: none;
		// overflow: hidden;
	}

	a {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		overflow: hidden;
		border-bottom: 1px solid;

		&:hover {
			text-decoration: none;
			border-color: transparent;
		}
	}

	.slide #s-inr {
        transform: translateY(1.6em);
        
        &.bar {
            &.left #s-inr {
                transform: translateX(-100%);
                opacity: 1;
            }

            #s-inr {
                transform: translateX(100%);
                opacity: 1;
            }
        }
    }


	#c-bns {
		display: flex;
		justify-content: space-between;
		margin-top: 1.4em;
        
        #c-p-bn{
            @include btn-default;
            margin-top: 0;
            width: fit-content;
            text-transform: uppercase;
        }

        #c-s-bn{
            @include btn-default($gray-900,$secondary);
            margin-top: 0;
            width: fit-content;
            text-transform: uppercase;
            opacity: .25;

            &:hover{
                opacity: 1;
            }
        }
	}

	// Toggle
	.b-tg {
		display: inline-block;
        position: absolute;
		top: 0;
		right: 1.2em;
		bottom: 0;
		margin: auto;
		width: 1em;
		height: 1em;
		vertical-align: middle;
		user-select: none;
		cursor: pointer;

		.c-tgl {
            position: absolute;
			display: none;
			top: 0;
			left: 0;
			margin: 0;
			border: 0;
			width: 0;
			height: 0;
			border-radius: 0;
			cursor: pointer;

			&:disabled {
				cursor: not-allowed;
			}

			&:checked {
				~ {
					.c-tg {
						&:before{
							color: $white;
							background: {
								color: $primary;
								image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='#{rgba($white,.99)}' viewBox='0 0 16 16'%3E%3Cpath d='M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z'/%3E%3C/svg%3E");
							}
							border: 1px solid $primary;
						}
					}
				}
			}
			
			~ {
				.c-tg.c-ro {
					&:before{
                        color: $white;
                        background: {
                            color: rgba($primary,.25);
                            image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='#{rgba($white,.99)}' viewBox='0 0 16 16'%3E%3Cpath d='M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z'/%3E%3C/svg%3E");
                        }
                        border: 1px solid rgba($primary,.25);
                    }
				}
			}
		}
		
		.c-tg {
			position: relative;
			display: block;
			height: 1em;
			width: 1em;
			pointer-events: none;

			&:before{
				content:'';
				display: block;
				position: absolute;
				top: .05em;
				left: 0;
				margin: {
					top: 0;
					right: 1em;
					bottom: 0;
				}
				height: 100%;
				width: 100%;
				color: transparent;
				text-align: center;
				line-height: 1.4;
				background: {
					image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='#{rgba($white,0)}' viewBox='0 0 16 16'%3E%3Cpath d='M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z'/%3E%3C/svg%3E");
					color: transparent;
					size: 80%;
					position: center;
				}
				border: {
					width: 1px;
					style: solid;
					color: $gray-300;
					radius: 0;
				}
				box-sizing: border-box;
				opacity: 1;
			}

			&.c-ro {
				cursor: not-allowed;
			}
		}
		
		span.t-lb {
			position: absolute;
			top: 0;
			width: 0;
			height: 0;
			opacity: 0;
			overflow: hidden;
			pointer-events: none;
			z-index: -1;
		}
	}

	.cc-link {
        @include btn-underline;
		cursor: pointer;
	}

	.b-bn {
		.exp:before {
            content: '';
            display: inline-block;
            position: absolute;
            padding: .2em;
            margin-right: 15px;
            top: 50%;
            left: 1.2em;
            border: solid $gray-500;
            border-width: 0 1px 1px 0;
            transform: translateY(-50%) rotate(45deg);
        }
	}

	.act {
		.b-bn {
			.exp {
				&:before {
					content: '';
					border: solid $gray-500;
					border-width: 0 1px 1px 0;
					padding: .2em;
					display: inline-block;
					position: absolute;
					margin-right: 15px;
					transform: translateY(-50%) rotate(45deg);
					left: 1.2em;
					top: 50%;
				}
			}

			.b-tl {
				&:before {
					transform: translateY(-20%) rotate(225deg);
				}
			}
		}
	}

	// .on-i {
	// 	display: block;
	// 	position: absolute;
    //     height: 100%;
	// 	width: 50%;
	// 	right: 0;
	// 	left: 0;
	// 	text-align: center;
	// 	opacity: 0;
	// 	transition: opacity .15s ease;

	// 	&:before {
	// 		border: solid $gray-200;
	// 		border-width: 0 2px 2px 0;
	// 		padding: .1em;
	// 		display: inline-block;
	// 		padding-bottom: .45em;
	// 		content: '';
	// 		margin: 0 auto;
	// 		transform: rotate(45deg);
	// 		top: .37em;
	// 		left: .75em;
	// 		position: absolute;
	// 	}
	// }

	// .off-i {
	// 	display: block;
	// 	position: absolute;
	// 	height: 100%;
	// 	width: 50%;
	// 	right: 0;
	// 	text-align: center;
	// 	transition: opacity .15s ease;

	// 	&:before,
	// 	&:after {
	// 		content: ' ';
	// 		right: .8em;
	// 		top: .42em;
	// 		height: .7em;
	// 		width: .09375em;
	// 		display: block;
	// 		background: $gray-200;
	// 		margin: 0 auto;
	// 		position: absolute;
	// 		transform-origin: center;
	// 		transform: rotate(45deg);
	// 	}
	// }

	.cloud {
		#c-inr {
			display: table;
			width: 100%;
		}

		#c-inr-i {
			width: 70%;
			display: table-cell;
			vertical-align: top;
			padding-right: 2.4em;
		}

		#c-txt {
			font-size: 0.85em;
		}

		#c-bns {
			min-width: 170px;
			display: table-cell;
			vertical-align: middle;
		}
	}
}

//
// Consent::Message
//
#cm {
	font-family: inherit;
	padding: 1em 1.4em 1.3em 1.4em;
	position: fixed;
	background: $white;
	max-width: 24.2em;
	width: 100%;
	bottom: 1.250em;
	right: 1.250em;
	box-shadow: var(--cc-consent-modal-box-shadow);
	opacity: 0;
	visibility: hidden;
	transform: scale(.95);
	line-height: initial;
	z-index: 1;

    
	.c_link {
        &:hover,
        &.active {
            color: $black;
			background: $gray-400;
		}
	}
    
    &.top {
        bottom: auto;
        top: 1.250em;
    }
    
    &.left {
        right: auto;
        left: 1.250em;
    }
    
    &.right {
        left: auto;
        right: 1.250em;
    }
    
    &.bar{
        &.slide{
            opacity: 1;
            transform: translateY(100%);

            &.top{
                transform: translateY(-100%);
            }
        }
    }

    &.slide{
        transform: translateY(1.6em);

        &.top{
            transform: translateY(-1.6em);
        }
    }
    
    &.box{
        &.center {
            left: 1em;
            right: 1em;
            // padding: {
            //     top: 3em;
            //     right: 3em;
            //     bottom: 2.5em;
            //     left: 3em;
            // }
            margin: 0 auto;
        }

        &.middle,
        &.cloud.middle{
            top: 50%;
            transform: translateY(-37%);
            bottom: auto;
        }
        
        &.middle.zoom {
            transform: scale(.95) translateY(-50%);
        }
    }
    
    &.cloud {
        left: 1em;
        right: 1em;
        margin: 0 auto;
        padding: 1.2em 1.7em;
        width: unset;
        max-width: 50em;
        text-align: center;
        overflow: hidden;
        
        &.middle.zoom {
            transform: scale(.95) translateY(-50%);
        }

        &.right {
            margin-left: 1.25em;
        }

        &.left {
            margin-right: 1.25em;
        }
        
        .c-bn {
            margin: .5rem 0 0 0;
            width: 100%;

            &:first-child {
                margin: 0;
            }
        }

        #c-bns{
            display: flex;
            flex-direction: column;
            align-items: center;

            #c-p-bn{
                font-size: .9em;
            }
    
            #c-s-bn{
                font-size: .9em;
            }
        }
    }
    
    &.bar {
        width: 100%;
        max-width: 100%;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 0;
        position: fixed;
        padding: 2em;

        &.top {
            top: 0;
        }

        &.right {
            left: 0;
            right: 0;
        }

        &.left {
            left: 0;
            right: 0;
        }

        #c-inr {
            max-width: 32em;
            margin: 0 auto;
        }
    
        #c-bns {
            max-width: 33.75em;
        }
    
        #cs {
            padding: 0;
        }
    }
}

// Message::Overlay
#cm-ov {
	transition: visibility .25s linear, opacity .25s ease, transform .25s ease !important;
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	visibility: hidden;
	opacity: 0;
	background: $gray-900;
	display: none;
}

//
// Consent::Settings
//
#s-cnt{

    #cs {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        padding: 0 1.7em;
        width: 100%;
        height: 100%;        
    }

    #s-hdr {
        background: $white;
        position: absolute;
        top: 0;
        width: 100%;
        display: table;
        padding: 0 1.8em;
        height: 4.75em;
        vertical-align: middle;
        z-index: 2;
        border-bottom: 1px solid var(--cc-section-border);
    }
    
    #s-bns {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 1em 1.8em;
        height: 4.75em;
        background: $white;
        border-top: 1px solid var(--cc-section-border);
    
        button {
            @include btn-default;
			font-size: .9em;
        }

		#s-rall-bn{
			@include btn-default($gray-900,$secondary);
			font-size: .9em;
		}
    }
    
    #s-ttl {
        display: table-cell;
        vertical-align: middle;
        font-size: 1em;
    }
    
    #s-c-bnc {
        display: table-cell;
        vertical-align: middle;
    }
}


// Settings::Overlay
#cs-ov {
	display: none;
    position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: $gray-900;
	visibility: hidden;
	transition: visibility .25s linear, opacity .25s ease, transform .25s ease !important;
	opacity: 0;
	z-index: z('cookieconsent');
}

#s-inr {
	height: 100%;
	position: relative;
	max-width: 45em;
	margin: 0 auto;
	transform: scale(.96);
	padding-top: 4.75em;
	padding-bottom: 4.75em;
	box-shadow: rgba(3, 6, 9, .26) 0px 13px 27px -5px;
	background-color: $white;
	border-radius: 0;
	visibility: hidden;
	overflow: hidden;
	opacity: 0;
}

// Setting::Scrollable
#s-bl {
	display: block;
	padding: 1.3em 1.6em;
	height: 100%;
	width: 100%;
	scrollbar-width: thin;
	overflow-x: hidden;
	overflow-y: overlay;
    outline: none;

    @include media ('>688') {
        &:-webkit-scrollbar {
            width: .8em;
            height: 100%;
            background: transparent;
            border-radius: 0 0.250em 0.250em 0;
        }

        &:-webkit-scrollbar-thumb {
            border: 0.25em solid $white;
            background: var(--cc-webkit-scrollbar-bg);
            border-radius: 100em;
            &:hover {
                background: var(--cc-webkit-scrollbar-bg-hover);
            }

        }

        &:-webkit-scrollbar-button {
            width: 10px;
            height: 5px;
        }
    }

	// Accordion
	.b-acc {
		display: none;
		max-height: 0;
		padding-top: 0;
		margin-bottom: 0;
		overflow: hidden;

		.p {
			margin-top: 0;
			padding: 1em;
		}
	}

	.act {
		.b-acc { // Accordion
			display: block;
			max-height: 100%;
			border-radius: 0;
			overflow: hidden;
		}
	}


	.title {
		margin-top: 1.4em;

		&:first-child {
			margin-top: 0;
		}
	}

	.b-bn {
		margin-top: 0;
		position: relative;
	}

	.c-bl {
		padding: 1em;
		margin-bottom: .4rem;
		border: 1px solid var(--cc-section-border);
		transition: background-color .25s ease;

		&:hover {
			// background: var(--cc-cookie-category-block-bg);
		}

		&:last-child {
			margin-bottom: .5em;
		}

		&:first-child {
			transition: none;
			padding: 0;
			margin-top: 0;
			border: none;
			margin-bottom: 2em;
		}

		&:not(.b-ex) {
			&:first-child {
				&:hover {
					background: transparent;
					background: unset;
				}
			}
		}
	}

	.c-bl.b-ex {
		padding: 0;
		border: 1px solid $gray-200;
		// background: var(--cc-cookie-category-block-bg);
		transition: none;
        
        &:first-child {
			margin-bottom: .5em;
		}

		+ {
			.c-bl {
				margin-top: 2em;
			}

			.c-bl.b-ex {
				margin-top: 0;
			}
		}
	}

	table {
		border: none;
		text-align: left;
		border-collapse: collapse;
		width: 100%;
		padding: 0;
		margin: 0;
		overflow: hidden;
	}

	th {
		border: none;
		text-align: left;
		vertical-align: top;
		font-size: .8em;
		padding-left: 1.2em;
		font-family: inherit;
		padding: 1em 1rem;
		font-weight: 600;
	}

	td {
		border: none;
		padding: 0.8em 0.625em;
		text-align: left;
		vertical-align: top;
		font-size: .8em;
		padding-left: 1.2em;
	}

	tbody {
		tr {
			transition: background-color .25s ease;

			&:hover {
				background: $gray-100;
			}
		}
	}

	caption {
		padding: 0.5rem 1rem;
		font-size: .9em;
		border-bottom: 1px solid $gray-300;
		font-weight: 600;
	}

	thead {
		tr {
			&:first-child {
				border-bottom: 1px solid $gray-300;
			}

		}

	}
}

// Animations
.c--anim {
	#cm {
		transition: visibility .25s linear, opacity .25s ease, transform .25s ease !important;
	}

	#s-cnt {
		transition: visibility .25s linear, opacity .25s ease, transform .25s ease !important;
	}

	#s-inr {
		transition: visibility .25s linear, opacity .25s ease, transform .25s ease !important;
	}

	// .c-bn {
	// 	transition: background-color .25s ease !important;
	// }

	#cm.bar.slide {
		transition: visibility .4s ease, opacity .4s ease, transform .4s ease !important;
		+ {
			#cm-ov {
				transition: visibility .4s ease, opacity .4s ease, transform .4s ease !important;
			}
		}
	}

	.bar.slide {
		#s-inr {
			transition: visibility .4s ease, opacity .4s ease, transform .4s ease !important;
		}

		+ {
			#cs-ov {
				transition: visibility .4s ease, opacity .4s ease, transform .4s ease !important;
			}
		}
	}

	#cs-ov {
		display: block;
	}
}

.show--consent {
	.c--anim {
		#cm {
			opacity: 1;
			transform: scale(1);
			visibility: visible !important;

            &.bar {
                opacity: 1;
                transform: scale(1);
                visibility: visible !important;
            }

            &.box.middle,
            &cloud.middle {
                transform: scale(1) translateY(-50%);
            }
		}
	}
}

.show--settings {
	.c--anim {
		#s-inr {
			opacity: 1;
			transform: scale(1);
			visibility: visible !important;
		}

		.bar.slide {
			#s-inr {
				opacity: 1;
				transform: scale(1);
				visibility: visible !important;
			}
		}

		#s-cnt {
			visibility: visible !important;
		}

		#cs-ov {
			visibility: visible !important;
			opacity: var(--cc-overlay-opacity) !important;
		}

	}

	#cs-ov {
		display: block;
	}
}

#c-ttl {
    // @extend .h5;
	margin-bottom: .7em;
	font: {
        size: 1.05em;
        weight: 500;
    }
}

.cloud {
	#c-ttl {
		margin-top: -.15em;
	}
}

#c-txt {
	font-size: 0.9em;
	line-height: 1.5em;
}

#s-cnt {
    position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 101;
	display: table;
	height: 100%;
	visibility: hidden;

	button {
		+ {
			button {
				float: right;
				margin-left: .5rem;

				// &:hover,
                // &:active {
				// 	background: $gray-400;
				// 	color: $black;
				// }
			}
		}
	}

	#s-rall-bn {
		@include btn-default($gray-900,$secondary);
		float: none;
        opacity: .25;

        &:hover{
            opacity: 1;
        }
	}

	.b-bn {
		.b-tl {
			display: block;
			font-family: inherit;
			font-size: .95em;
			width: 100%;
			position: relative;
			padding: 1.3em 6.4em 1.3em 2.7em;
			background: none;
			transition: background-color .25s ease;

            &:hover,
			&:active {
				background: $gray-100;
			}
		}

		.b-tl.exp {
			cursor: pointer;
		}
	}

	.act {
		.b-bn {
			.b-tl {
				border-bottom-right-radius: 0;
				border-bottom-left-radius: 0;
			}
		}
	}

	.p {
		font-size: 0.9em;
		line-height: 1.5em;
		margin-top: .85em;
		color: $black;
	}
}

#s-c-bn {
	float: right;
	margin-left: .5rem;
	padding: 0;
	width: 1.7em;
	height: 1.7em;
	font-size: 1.45em;
	margin: 0;
	font-weight: initial;
	position: relative;
	overflow: hidden;

    &:hover,
	&:active {
		color: $black;
		background: $gray-400;
	}

	&:before,
    &:after {
		content: '';
		position: absolute;
		left: .82em;
		top: .58em;
		height: .6em;
		width: 1.5px;
		background: $black;
		transform: rotate(45deg);
		border-radius: 1em;
		margin: 0 auto;
	}

	&:after {
		transform: rotate(-45deg);
	}
}

#c-vln {
	display: table-cell;
	vertical-align: middle;
	position: relative;
}



#c_policy__text {
	height: 31.250em;
	overflow-y: auto;
	margin-top: 1.250em;
}

#c-s-in {
	position: relative;
	transform: translateY(-50%);
	top: 50%;
	height: calc(100% - 2.5em);
	max-height: 37.5em;
}

//
// Features::Force
//
.force--consent {
    overflow-y: hidden !important;
	height: auto !important;
	overflow-x: hidden !important;

	body {
		height: auto !important;
		overflow-x: hidden !important;
	}

	#s-cnt {
		width: 100vw;
	}

	#cs {
		width: 100vw;
	}

	.c--anim {
		#cm-ov {
			display: block;
		}
	}

	.cc_div {
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		width: 100vw;
		visibility: hidden;
		transition: visibility .25s linear;
	}

    &.show--settings {
        .c--anim {
            .cc_div {
                visibility: visible;
            }
        }
    }

    &.show--consent {
        .c--anim {
            #cm-ov {
                visibility: visible !important;
                opacity: var(--cc-overlay-opacity) !important;
            }
    
            .cc_div {
                visibility: visible;
            }
    
        }
    
        #cm-ov {
            display: block;
        }
    }
}

// @media screen and (max-width: 688px) {
// 	#cm {
// 		width: auto;
// 		max-width: 100%;
// 		margin: 0;
// 		padding: 1.2em !important;
// 		right: 1em;
// 		left: 1em;
// 		bottom: 1em;
// 		display: block;
// 		.c-bn {
// 			width: 100%;
// 			margin-right: 0;
// 		}

//         &.cloud {
//             max-width: 100%;
//             margin: 0;
//             padding: 1.2em !important;
//             bottom: 1em;
//             display: block;
//             left: 1em;
//             right: 1em;
//             width: auto;
            
//             .c-bn {
//                 font-size: .85em;
//             }
//         }

//         &.left {
//             width: auto;
//             max-width: 100%;
//             margin: 0;
//             padding: 1.2em !important;
//             right: 1em;
//             left: 1em;
//             bottom: 1em;
//             display: block;
//         }

//         &.right {
//             width: auto;
//             max-width: 100%;
//             margin: 0;
//             padding: 1.2em !important;
//             right: 1em;
//             left: 1em;
//             bottom: 1em;
//             display: block;
//         }
// 	}

// 	.force--consent {
// 		#cm {
// 			width: auto;
// 			max-width: 100vw;
// 		}

// 		#cm.cloud {
// 			width: auto;
// 			max-width: 100vw;
// 		}
// 	}

// 	#cm.top {
// 		top: 1em;
// 		bottom: auto;
// 	}

// 	#cm.bottom {
// 		bottom: 1em;
// 		top: auto;
// 	}

// 	#cm.bar.bottom {
// 		bottom: 0;
// 	}

// 	#s-bns {
// 		padding: 1em 1.3em;
// 		height: 7.9em;
// 	}

// 	.cc_div {
// 		.bar {
// 			#s-bns {
// 				padding: 1em 1.3em;
// 			}

// 			#s-inr {
// 				max-width: 100%;
// 				width: 100%;
// 			}

// 			#s-bl {
// 				padding: 1.2em;
// 			}

// 			#s-hdr {
// 				padding: 0 1.2em;
// 			}

// 		}

// 		.cloud {
// 			#c-inr-i {
// 				padding-right: 0;
// 				display: block;
// 				width: auto;
// 				min-width: unset;
// 			}

// 			#c-bns {
// 				display: block;
// 				width: auto;
// 				min-width: unset;
// 				margin-top: 1.625em;
// 			}

// 			#c-inr {
// 				display: block;
// 				width: auto;
// 				min-width: unset;
// 			}

// 			#c-txt {
// 				font-size: .9em;
// 			}

// 		}

// 		.b-tg {
// 			transform: scale(1.1);
// 			right: 1.1em;
// 		}

// 		.bns-t {
// 			.c-bn {
// 				font-size: 0.83em;
// 				padding: .9em 1.6em;
// 			}

// 		}

// 		.c-bn {
// 			width: 100%;
// 			margin-right: 0;
// 		}

// 		#c-bns {
// 			flex-direction: column;
// 		}

// 	}

// 	#cs {
// 		border-radius: 0;
// 		padding: 0;
// 	}

// 	#c-s-in {
// 		max-height: 100%;
// 		height: 100%;
// 		top: 0;
// 		transform: none;
// 	}

// 	#s-inr {
// 		margin: 0;
// 		padding-bottom: 7.9em;
// 		border-radius: 0;
// 	}

// 	#s-bl {
// 		padding: 1.2em;
// 		table {
// 			width: 100%;
// 			display: block;
// 		}

// 		thead {
// 			display: block;
// 			tr {
// 				position: absolute;
// 				top: -9999px;
// 				left: -9999px;
// 			}

// 		}

// 		tbody {
// 			display: block;
// 		}

// 		th {
// 			display: block;
// 		}

// 		td {
// 			display: block;
// 			border: none;
// 			position: relative;
// 			padding-left: 35%;
// 			&:before {
// 				position: absolute;
// 				left: 1rem;
// 				padding-right: 0.625em;
// 				white-space: nowrap;
// 				content: attr(data-column);
// 				color: $black;
// 				overflow: hidden;
// 				text-overflow: ellipsis;
// 			}

// 		}

// 		tr {
// 			display: block;
// 			border-top: 1px solid $gray-300;
// 		}

// 		caption {
// 			border-bottom: 0;
// 			display: block;
// 		}

// 	}

// 	#s-hdr {
// 		padding: 0 1.2em;
// 	}

// 	#s-inr.bns-t {
// 		padding-bottom: 10.5em;
// 	}

// 	.bns-t {
// 		#s-bns {
// 			height: 10.5em;
// 		}

// 	}

// 	#s-cnt {
//         display: block;
		
//         .b-bn {
// 			.b-tl {
// 				padding-top: 1.2em;
// 				padding-bottom: 1.2em;
// 			}

// 		}


// 		#s-rall-bn {
// 			margin-left: 0;
// 		}

// 		button {
// 			+ {
// 				button {
// 					margin-top: 0.625em;
// 					margin-left: 0;
// 					float: unset;
// 				}

// 			}

// 		}

// 	}

// 	#c-bns {
// 		button {
// 			+ {
// 				button {
// 					margin-top: 0.625em;
// 					margin-left: 0;
// 					float: unset;
// 				}

// 			}

// 		}
// 	}
// }


// Ovverrides
#cc--main{
    z-index: z('cookieconsent');

    @media (prefers-reduced-motion) {
        #cm {
            transition: none !important;
        }

        #s-cnt {
            transition: none !important;
        }

        #s-inr {
            transition: none !important;
        }
    }

    #cm.cloud{
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: fixed;
        right: 2vw;
        bottom: 2vw;
        left: 2vw;
        padding: 1.5em;
        font-size: 1.4rem;
        max-width: unset;
        background-color: $white;
        border: 1px solid mix($gray-200, $white);
        box-shadow: 0 1.25rem 5rem -3.75rem rgba($black, .75);
        will-change: background-color, border-color;
        transition: background-color 1.5s ease, border-color 1.5s ease;
        z-index: z('content');
    
        #c-inr{
            @include media('>iPhoneProMax'){
                display: flex;
                align-items: baseline;
                justify-content: space-between;
                
                #c-inr-i{
                    display: flex;
                    align-items: baseline;
                    width: auto;
    
                    #c-ttl{
                        display: flex;
                        // color: $primary;
                        color: $gray-700;
                        font-size: 1em;
                        letter-spacing: -.05ch;
    
                        &:after{
                            display: inline-block;
                            content: '—';
                            margin: {
                                right: .5ch;
                                left: .5ch;
                            }
                        }
                    }
    
                    #c-txt{
                        color: $gray-600;
                        font-size: 1em;
                    }
                }
            }
        }

        #c-bns{
            display: flex;
            flex-direction: row;
            justify-content: end;
            text-transform: uppercase;
            
            @include media('>iPhoneProMax'){
                margin: 0;
                flex: 1 1 0;
            }

            button:first-child{
                margin-right: 2ch;
            }
    
            #c-p-bn{
                @include btn-default;
                margin-top: 0;
                font-size: .9em;
                width: fit-content;
                text-transform: uppercase !important;
            }
    
            #c-s-bn{
                margin-top: 0;
                font-size: .9em;
                width: fit-content;
                text-transform: uppercase !important;
                opacity: .25;
    
                &:hover{
                    opacity: 1;
                }
            }
        }
    }
    
    // #s-bns {
    //     button {
    //         @include btn-default;
    //     }
    // }
}