// COLORS

// 👉 Change the variable's values or add new color variables.

$white:    #FFF;

$gray-100: #F8F9FA;
$gray-200: #E9ECEF;
$gray-300: #DEE2E6;
$gray-400: #CED4DA;
$gray-500: #ADB5BD;
$gray-600: #6C757D;
$gray-700: #495057;
$gray-800: #343A40;
$gray-900: #212529;

$black:    #000;

$blue:    #7583B5;
$indigo:  #6610F2;
$purple:  #6F42C1;
$pink:    #EF3975;
$red:     #D72731;
$orange:  #FD7E14;
$yellow:  #FFCD1B;
$green:   #28A745;
$teal:    #20C997;
$cyan:    #17A2B8;
$carrara: #EFEEE9;

$primary:       $blue;
$secondary:     $carrara;
$success:       $green;
$info:          $cyan;
$warning:       $yellow;
$danger:        $pink;
$light:         $gray-100;
$dark:          $gray-800;
