// MIXIN: Buttons
@mixin btn-default($color: $white, $background: $primary){
    display: inline-block;
    position: relative;
    padding: {
        top: .75em;
        right: 1.25em;
        bottom: .75em;
        left: 1.25em;
    }
    // color: $color;
    color: transparent;
    font: {
        family: $font-heading;
        size: 1.6rem;
        weight: 500;
    }
    letter-spacing: -.015em;
    text: {
        align: center;
        decoration: none;
        transform: uppercase;
    }
    border: {
        radius: 0;
        // width: 4px 4px;
        style: none;
        // color: $white;
    }
    text-shadow: 
        0 0 $color,
        0 5em $color;
    background-color: $background;
    transition: text-shadow .4s $easeInOutQuint;
    user-select: none;
    overflow: hidden;

    &:after{
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 100%;
        width: 100%;
        border: {
            width: .05em;
            style: dashed;
            color: $white;
        }
        box-sizing: border-box;
    }

    &:hover{
        // color: $color;
        background-color: mix($black, $background, 5%);
        text-shadow: 
            0 -5em $color,    
            0 0 $color;
    }

    &[disabled]{
        color: $gray-500;
        background-color: $gray-200;
        transition: none;
        cursor: not-allowed;

        &:hover{
            text-shadow: none;
        }
    }
}


@mixin btn-primary($color, $background){
    @include btn-default;
    color: $color;
    background-color: $background;
    will-change: color, background-color, border-color;
    transition: color .5s ease, background-color .5s ease, border-color .5s ease;
    user-select: none;

    &:hover,
    &:focus,
    &:active{
        color: $background;
        border-color: $background;
        background-color: $color;
        transition: color .5s ease, background-color .5s ease, border-color .5s ease;
    }
}

@mixin btn-underline($color: $primary){
    font-weight: 500;
    text-decoration: underline;
    border: none;
    background-color: transparent;
    user-select: none;

    &:hover{
        color: $color;
    }
}

@mixin btn-backlink($color: $primary){
    @include btn-underline($color);
    user-select: none;

    &:before{
        content: '';
        display: inline-block;
        height: 1.15em;
        width: 1.15em;
        vertical-align: -.25em;
        background: {
            image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-arrow-left-short' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z'/%3E%3C/svg%3E");
            size: contain;
            position: center;
            repeat: no-repeat;
        }
    }

    &:hover{
        &:before{
            background: {
                image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='#{rgba($color,.99)}' class='bi bi-arrow-left-short' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z'/%3E%3C/svg%3E");
            }
        }
    }
}

@mixin btn-nextlink($color: $primary){
    @include btn-underline($color);
    user-select: none;

    &:after{
        content: '';
        display: inline-block;
        height: 1.15em;
        width: 1.15em;
        vertical-align: -.25em;
        background: {
            image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-arrow-left-short' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z'/%3E%3C/svg%3E");
            size: contain;
            position: center;
            repeat: no-repeat;
        }
        transform: scaleX(-1);
    }

    &:hover{
        &:after{
            background: {
                image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='#{rgba($color,.99)}' class='bi bi-arrow-left-short' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z'/%3E%3C/svg%3E");
            }
        }
    }
}

@mixin btn-icon($color: $white, $background: $primary, $icon: none){
    @include btn-default($color, $background);
    padding: 0;
    height: 40px;
    width: 40px;

    &:before{
        content: '';
        display: inline-block;
        height: 1.15em;
        width: 1.15em;
        vertical-align: -.25em;
        background: {
            image: url("#{$icon}");
            size: contain;
            position: center;
            repeat: no-repeat;
        }
        pointer-events: none;
    }
}

@mixin nav-link($color: $black){
    position: relative;
    // color: $color;
    color: transparent;
    font: {
        family: $font-heading;
        size: 1.6rem;
        weight: 500;
    }
    letter-spacing: -.015em;
    text: {
        align: center;
        decoration: none;
        transform: uppercase;
    }
    border: {
        radius: 0;
        style: none;
    }
    text-shadow: 
        0 0 $color,
        0 5em $color;
    transition: text-shadow .4s $easeInOutQuint;
    user-select: none;
    overflow: hidden;

    &:after{
        content: '';
        display: block;
        position: absolute;
        height: 5px;
        width: 100%;
        bottom: 0;
        background-color: $primary;
        will-change: transform;
        transition: transform .25s $easeInOutQuint;
        transform-origin: center bottom;
        transform: scaleY(0);
    }

    &.active,
    &.current-menu-item,
    &:hover{
        text-shadow: 
            0 -5em $color,    
            0 0 $color;

        &:after{
            transform: scaleY(1);
        }
    }

    &[disabled]{
        color: $gray-500;
        transition: none;
        cursor: not-allowed;

        &:hover{
            text-shadow: none;
        }
    }
}