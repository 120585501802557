// TYPOGRAPHY

// ✅ If you want you could create some variables fort easy font changing
// Paste the node_modules/bootstrap/scss/_variables.scss in there and change the values you want

// 👇 Something like this one below
$font-heading: 'Nollasans';
$font-body: 'Public Sans';


* {
    /* Better Font Rendering */
    // text-rendering: optimizeLegibility; ⚠️ Optional - That Optimize Legibility (slower rendering)
    // text-rendering: optimizeSpeed; ⚠️ Optional - That Optimize Speed (faster rendering)
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    font-family: $font-body, sans-serif;
    // font-size: 16px; ⚠️ Optional - Usually the browsers set this value to 16px (that property is the base of a rem > 1rem = 16px)
}

h1,
.h1 {
    font-size: 3rem;
    font-weight: 700;
    font-family: $font-heading, serif;
}

h2,
.h2 {
    font-size: 2.4rem;
    font-weight: 600;
    font-family: $font-heading, serif;
}


h3,
.h3 {
    font-size: 1.75rem;
    font-weight: 600;
    font-family: $font-heading, serif;
}

h4,
.h4 {
    font-size: 1.5rem;
    font-family: $font-heading, serif;
    // font-weight: 400;
}

h5,
.h5 {
    font-size: 1.2rem;
    font-family: $font-heading, serif;
    // font-weight: 400;
}

h6,
.h6{
    font-size: 1rem;
    font-family: $font-heading, serif;
}

p {
    color: $gray-700;
    font-size: 1em;
}

.title{
    text-transform: uppercase;
}
